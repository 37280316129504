<template>
  <div align="left" class="p-3">
    <div v-if="!isLoading">
      <client-details :askingMode="askingMode"/>
    </div>
    <quote-loading v-if="isLoading"/>
  </div>
</template>

<script>
import ClientDetails from "@/view/pages/protection/quote-form/ClientDetails.vue";
import QuoteLoading from "@/view/pages/protection/quote-form/QuoteLoading.vue";

export default {
  name: "quote-form",
  data() {
    return {
      askingMode: "quote"
    }
  },
  computed: {
    isLoading: {
      get() {
        return this.$store.state.pmi.isLoading;
      }
    }
  },
  mounted() {
    // Signal parent window
    var pluginMessage = { 
      messageType: "landingPage",
      payload: {}
    };
    window.parent.postMessage(JSON.stringify(pluginMessage),"*");
    this.$resetFrame();
  },
  components: {
    ClientDetails,
    QuoteLoading
  }
}
</script>
