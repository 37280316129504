<template>
  <div>
    <b-row>
      <b-col md="12">
        <div class="pb-3 pt-3">
          <h1 class="header-text" v-bind:style="$store.getters.writingColourBlockText">Compare quotes in minutes</h1>
        </div>
        
        <b-row>
          <b-col xl="12" lg="12" md="12" sm="12">
            <b-form-group>
              <template v-slot:label>
                <div class="d-flex flex-row v-align-center justify-content-start">
                  <div>
                    <span>What type of cover would you like?</span>
                    <div class="ml-1 d-inline-block" :id="`cover-type`">
                      <span class="not-sure" @click="() => showCoverType = !showCoverType" v-bind:style="$store.getters.infoBubble">Not sure?</span>
                    </div>
                  </div>
                </div>
                <div v-bind:style="$store.getters.infoBubble" v-if="showCoverType" class="mt-3">
                  <p><strong>Family cover</strong> – Also known as "Level term life insurance", provides a fixed amount of coverage for the duration of the policy. This type of insurance is often taken out to ensure that your loved ones receive a lump sum of money in the event of your passing.</p>
                  <p><strong>Mortgage cover</strong> – Also known as "Decreasing term life insurance", offers coverage that gradually decreases over the length of the 
                  policy. This type of insurance is typically used to cover a repayment mortgage.<br/>If you need help determining the appropriate amount of coverage for
                    your specific needs, please don't hesitate to call one of our advisors at (insert number here). We're here to assist you with any questions or concerns you may have.</p>
                </div>
              </template>

              <b-form-radio-group 
                class="rq-toggle block-view"
                v-model="quoteType"
                name="policy type"
                buttons
              >
                <b-form-radio
                  class="d-flex w-100 sm-font mb-3 justify-content-start" 
                  v-for="(item,i) in quoteTypeOptions" 
                  :disabled="askingMode == 'application'"
                  :key="i" 
                  :value="item.value" 
                  v-bind:style="[item.value == quoteType ? $store.getters.buttonBox : {}]"
                >
                  <template v-if="item.value == quoteType"><b-icon icon="check2-circle" class="mr-4"></b-icon></template>
                  <template v-if="item.value != quoteType"><b-icon icon="circle" class="mr-4"></b-icon></template>
                  <div class="d-flex flex-column text-left">
                    <div class="btn-label">{{item.text}}</div>
                    <div class="btn-description">{{item.description}}</div>
                  </div>
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="pb-3 pt-3">
          <div class="mb-4 mt-4">To show you low cost life insurance quotes we just need to ask you a few questions.</div>
        </div>

        <div class="pb-3 pt-3">
          <b-row>
            <b-col
              xl="12" lg="12" md="12" sm="12"
            >
              <b-row>
                <b-col>
                  <b-form-group label="Title" v-slot="{ ariaDescribedby }">
                    <b-form-select
                      v-model="pTitle"
                      :options="titleOptions"
                      :aria-describedby="ariaDescribedby"
                      name="pTitle"
                      buttons
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="First name">
                    <b-form-input
                      v-model="pFirstName"
                      v-validate="{ required: true }"
                      :state="validateState('pFirstName')"
                      aria-describedby="pFirstName-feedback"
                      data-vv-as="first name"
                      name="pFirstName"
                      type="text"
                    ></b-form-input>
                    <b-form-invalid-feedback id="pFirstName-feedback">{{ veeErrors.first('pFirstName') }}</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Surname">
                    <b-form-input
                      v-model="pLastName"
                      v-validate="{ required: true }"
                      :state="validateState('pLastName')"
                      aria-describedby="pLastName-feedback"
                      data-vv-as="surname"
                      name="pLastName"
                      type="text"
                    ></b-form-input>
                    <b-form-invalid-feedback id="pLastName-feedback">{{ veeErrors.first('pLastName') }}</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-form-group label="Gender">
                <template v-slot:label>
                  <div class="d-flex flex-row v-align-center justify-content-start">
                    <div>
                      <span>Gender at birth</span>
                    </div>
                  </div>
                </template>

                <b-form-radio-group
                  class="rq-toggle w-100"
                  v-model="pGender"
                  name="radios-btn-default"
                  buttons
                >
                  <b-form-radio
                    class="d-flex flex-fill"
                    v-for="(item,i) in genderOptions" 
                    :key="i" 
                    :disabled="askingMode == 'application'"
                    :value="item.value" 
                    v-bind:style="[item.value == pGender ? $store.getters.buttonBox : {}]"
                  >
                    <template v-if="item.value == pGender"><b-icon icon="check2-circle" class="mr-4"></b-icon></template>
                    <template v-if="item.value != pGender"><b-icon icon="circle" class="mr-4"></b-icon></template>
                    {{item.text}}
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              
              <b-row>
                <b-col>
                  <b-form-group label="Marital Status">
                    <b-form-select
                      :options="maritalStatusOptions"
                      v-model="pMaritalStatus"
                    >
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              
              <b-row>
                <b-col>
                  <label for="exampleInputEmail1">Date of birth</label>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-select
                    :options="dayOptions"
                    v-model="pDobDay"
                    v-validate="{ required: true }"
                    placeholder="Select day"
                    data-vv-as="birth date"
                    :state="validateState('pDobDay')"
                    name="pDobDay"
                  >
                  </b-form-select>
                  <b-form-invalid-feedback id="pDobDay-feedback">{{ veeErrors.first('pDobDay') }}</b-form-invalid-feedback>
                </b-col>
                <b-col>
                  <b-form-select
                    :options="monthOptions"
                    v-model="pDobMonth"
                    v-validate="{ required: true }"
                    data-vv-as="birth month"
                    :state="validateState('pDobMonth')"
                    placeholder="Select month"
                    name="pDobMonth"
                  >
                  </b-form-select>
                  <b-form-invalid-feedback id="pDobDay-feedback">{{ veeErrors.first('pDobMonth') }}</b-form-invalid-feedback>
                </b-col>
                <b-col>
                  <b-form-select
                    :options="yearOptions"
                    v-model="pDobYear"
                    v-validate="{ required: true }"
                    data-vv-as="birth year"
                    :state="validateState('pDobYear')"
                    placeholder="Select year"
                    name="pDobYear"
                  >
                  </b-form-select>
                  <b-form-invalid-feedback id="pDobYear-feedback">{{ veeErrors.first('pDobYear') }}</b-form-invalid-feedback>
                </b-col>
              </b-row>      
            </b-col>
          </b-row>

          <b-row>
            <b-col xl="12" lg="12" md="12" sm="12">
              <b-form-group class="mt-6" label="Phone number">
                <b-form-input
                  v-model="landlineNumber"
                  aria-describedby="landlineNumber-feedback"
                  data-vv-as="landlin number"
                  name="telephoneNumber"
                  type="text"
                ></b-form-input>
                <b-form-invalid-feedback id="landlineNumber-feedback">{{ veeErrors.first('landlineNumber') }}</b-form-invalid-feedback>
              </b-form-group>

              <b-form-group label="Email address">
                <b-form-input
                  v-model="email"
                  v-validate="'required|email'"
                  :state="validateState('email')"
                  aria-describedby="email-feedback"
                  name="email"
                  type="email"
                ></b-form-input>
                <b-form-invalid-feedback id="telephoneNumber-feedback">{{ veeErrors.first('email') }}</b-form-invalid-feedback>
              </b-form-group>

              <b-form-group class="mt-6" label="Mobile number">
                <b-form-input
                  v-model="telephoneNumber"
                  v-validate="'required|uk-mobile'"
                  :state="validateState('telephoneNumber')"
                  aria-describedby="telephoneNumber-feedback"
                  data-vv-as="mobile number"
                  name="telephoneNumber"
                  type="text"
                ></b-form-input>
                <b-form-invalid-feedback id="telephoneNumber-feedback">{{ veeErrors.first('telephoneNumber') }}</b-form-invalid-feedback>
              </b-form-group>

              <b-form-group label="Postcode">
                <b-form-input
                  v-model="postcode"
                  :disabled="askingMode == 'application'"
                  v-validate="'required'"
                  :state="validateState('postcode')"
                  aria-describedby="postcode-feedback"
                  name="postcode"
                  type="text"
                ></b-form-input>
                <b-form-invalid-feedback id="postcode-feedback">{{ veeErrors.first('postcode') }}</b-form-invalid-feedback>
              </b-form-group>

              <div v-if="askingMode == 'application'">
                <div class="mb-4"><b-button variant="primary" v-bind:style="$store.getters.buttonBox" @click="getPostcodeAddress">Find address</b-button> <span class="ml-6 enter-address" @click="openAddressBox">Enter address manually</span></div>
                
                <b-form-group v-if="showAddresses" label="Choose your address" v-slot="{ ariaDescribedby }">
                  <b-form-select
                    v-model="selectedAddress"
                    :options="addresses"
                    :aria-describedby="ariaDescribedby"
                  ></b-form-select>
                </b-form-group>
                
                <div v-if="enterAddressManually">
                  <b-form-group label="Address 1">
                    <b-form-input
                      v-model="address1"
                      v-validate="{ required: true }"
                      :state="validateState('address1')"
                      aria-describedby="address1-feedback"
                      data-vv-as="address1"
                      name="address1"
                      type="text"
                    ></b-form-input>
                    <b-form-invalid-feedback id="address1-feedback">{{ veeErrors.first('address1') }}</b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group label="Address 2">
                    <b-form-input
                      v-validate="{ required: true }"
                      :state="validateState('address2')"
                      aria-describedby="address2-feedback"
                      v-model="address2"
                      name="address2"
                      type="text"
                    ></b-form-input>
                    <b-form-invalid-feedback id="address2-feedback">{{ veeErrors.first('address2') }}</b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group label="County">
                    <b-form-input
                      v-model="county"
                      name="county"
                      type="text"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group label="Town">
                    <b-form-input
                      v-model="town"
                      name="town"
                      type="text"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col xl="12" lg="12" md="12" sm="12">
              <b-form-group>
                <template v-slot:label>
                  <div class="d-flex flex-row v-align-center justify-content-start">
                    <div>
                      <span>Who would you like to cover?</span>
                      <div class="ml-1 d-inline-block" :id="`who-cover`">
                        <span class="not-sure" @click="() => showCoverWho = !showCoverWho" v-bind:style="$store.getters.infoBubble">Not sure?</span>
                      </div>
                    </div>
                  </div>
                  <div v-bind:style="$store.getters.infoBubble" v-if="showCoverWho" class="mt-3">
                    <p>
                      If you request a joint policy, we'll provide you with quotes for two single policies as well. This will allow you to compare your options and decide 
                      whether two single policies may better suit your needs rather than a joint policy. By taking out two single policies, you'll typically get twice as 
                      much coverage for only a slightly higher total monthly payment.
                    </p>
                  </div>
                </template>

                <b-form-radio-group 
                  class="rq-toggle w-100"
                  v-model="policyType"
                  name="policy type"
                  buttons
                >
                  <b-form-radio
                    class="d-flex flex-fill sm-font" 
                    v-for="(item,i) in policyTypeOptions" 
                    :disabled="askingMode == 'application'"
                    :key="i" 
                    :value="item.value" 
                    v-bind:style="[item.value == policyType ? $store.getters.buttonBox : {}]"
                  >
                    <template v-if="item.value == policyType"><b-icon icon="check2-circle" class="mr-4"></b-icon></template>
                    <template v-if="item.value != policyType"><b-icon icon="circle" class="mr-4"></b-icon></template>
                    {{item.text}}
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col
              v-if="policyType == 'joint'" 
              xl="12" lg="12" md="12" sm="12"
            >
              <h3 class="header-text" v-bind:style="$store.getters.writingColourBlockText">Second person</h3>
              <b-row>
                <b-col>
                  <b-form-group label="Title" v-slot="{ ariaDescribedby }">
                    <b-form-select
                      v-model="sTitle"
                      :options="titleOptions"
                      :aria-describedby="ariaDescribedby"
                      name="sTitle"
                      buttons
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="First name">
                    <b-form-input
                      v-model="sFirstName"
                      v-validate="{ required: true }"
                      :state="validateState('sFirstName')"
                      aria-describedby="sFirstName-feedback"
                      data-vv-as="first name"
                      name="sFirstName"
                      type="text"
                    ></b-form-input>
                    <b-form-invalid-feedback id="sFirstName-feedback">{{ veeErrors.first('sFirstName') }}</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Surname">
                    <b-form-input
                      v-model="sLastName"
                      v-validate="{ required: true }"
                      :state="validateState('sLastName')"
                      aria-describedby="sLastName-feedback"
                      data-vv-as="surname"
                      name="sLastName"
                      type="text"
                    ></b-form-input>
                    <b-form-invalid-feedback id="sLastName-feedback">{{ veeErrors.first('sLastName') }}</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-form-group>
                <template v-slot:label>
                  <div class="d-flex flex-row v-align-center justify-content-start">
                    <div>
                      <span>Gender at birth</span>
                    </div>
                  </div>
                </template>

                <b-form-radio-group
                  class="rq-toggle w-100"
                  v-model="sGender"
                  name="radios-btn-default"
                  buttons
                >
                  <b-form-radio
                    class="d-flex flex-fill"
                    v-for="(item,i) in genderOptions" 
                    :key="i" 
                    :disabled="askingMode == 'application'"
                    :value="item.value" 
                    v-bind:style="[item.value == sGender ? $store.getters.buttonBox : {}]"
                  >
                    <template v-if="item.value == sGender"><b-icon icon="check2-circle" class="mr-4"></b-icon></template>
                    <template v-if="item.value != sGender"><b-icon icon="circle" class="mr-4"></b-icon></template>
                    {{item.text}}
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>

              <b-row>
                <b-col>
                  <b-form-group label="Marital Status">
                    <b-form-select
                      :options="maritalStatusOptions"
                      v-model="sMaritalStatus"
                    >
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <label for="exampleInputEmail1">Date of birth</label>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-select
                    :options="dayOptions"
                    v-model="sDobDay"
                    v-validate="{ required: true }"
                    data-vv-as="birth date"
                    name="sDobDay"
                    :state="validateState('sDobDay')"
                    placeholder="Select day"
                  >
                  </b-form-select>
                  <b-form-invalid-feedback id="sDobDay-feedback">{{ veeErrors.first('sDobDay') }}</b-form-invalid-feedback>
                </b-col>
                <b-col>
                  <b-form-select
                    :options="monthOptions"
                    v-model="sDobMonth"
                    v-validate="{ required: true }"
                    data-vv-as="birth month"
                    name="sDobMonth"
                    :state="validateState('sDobMonth')"
                    placeholder="Select month"
                  >
                  </b-form-select>
                  <b-form-invalid-feedback id="sDobMonth-feedback">{{ veeErrors.first('sDobMonth') }}</b-form-invalid-feedback>
                </b-col>
                <b-col>
                  <b-form-select
                    :options="yearOptions"
                    v-model="sDobYear"
                    v-validate="{ required: true }"
                    data-vv-as="birth year"
                    name="sDobYear"
                    :state="validateState('sDobYear')"
                    placeholder="Select year"
                  >
                  </b-form-select>
                  <b-form-invalid-feedback id="sDobYear-feedback">{{ veeErrors.first('sDobYear') }}</b-form-invalid-feedback>
                </b-col>
              </b-row> 
            </b-col>
          </b-row>

          <b-row>
            <b-col xl="12" lg="12" md="12" sm="12">
              <div v-if="drivenType == 'benefit'">
                <b-form-group v-if="benefitType == 'death' || benefitType == 'deathCic'">
                  <template v-slot:label>
                    <div class="d-flex flex-row v-align-center justify-content-start">
                      <div>
                        <span>How much cover are you looking for?</span>
                        <div class="ml-1 d-inline-block" :id="`how-much-cover`">
                          <span class="not-sure" @click="() => showHowMuchCover = !showHowMuchCover" v-bind:style="$store.getters.infoBubble">Not sure?</span>
                        </div>
                      </div>
                      <div class="small-label">Perhaps enough to cover your mortgage or a lump sum to leave for your family.</div>
                    </div>
                    <div v-bind:style="$store.getters.infoBubble" v-if="showHowMuchCover" class="mt-3">
                      <p>
                        Are you concerned about how your family will manage financially if something unexpected were to happen to you? Perhaps you have a large debt, like 
                        a mortgage, that you want to make sure is covered. We can help you find the right amount of insurance to meet your needs. If you're not sure how 
                        much coverage you require, please give us a call at 0800 5837288 to speak with one of our advisors. We're here to help you protect what matters 
                        most.
                      </p>
                    </div>
                  </template>
                  
                  <b-input-group prepend="£" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      v-model="deathBenefitAmount"
                      v-validate="{ required: true }"
                      :state="validateState('deathBenefitAmount')"
                      aria-describedby="deathBenefitAmount-feedback"
                      data-vv-as="death benefit amount"
                      name="deathBenefitAmount"
                      type="number"
                    ></b-form-input>
                  </b-input-group>
                  <b-form-invalid-feedback id="deathBenefitAmount-feedback">{{ veeErrors.first('deathBenefitAmount') }}</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group label="Critical Illness Amount" v-if="benefitType == 'cic' || benefitType == 'deathCic'">
                  <b-form-input
                    v-model="criticalIllnessAmount"
                    v-validate="{ required: true }"
                    :state="validateState('criticalIllnessAmount')"
                    aria-describedby="criticalIllnessAmount-feedback"
                    data-vv-as="critical illness amount"
                    name="criticalIllnessAmount"
                    type="number"
                  ></b-form-input>
                  <b-form-invalid-feedback id="criticalIllnessAmount-feedback">{{ veeErrors.first('criticalIllnessAmount') }}</b-form-invalid-feedback>
                </b-form-group>
              </div>

              <b-form-group label="">
                <template v-slot:label>
                  <div class="d-flex flex-row v-align-center justify-content-start">
                    <div>
                      <span>How long do you need cover for?</span>
                      <div class="ml-1 d-inline-block" :id="`how-long-cover`">
                        <span class="not-sure" @click="() => showHowLongCover = !showHowLongCover" v-bind:style="$store.getters.infoBubble">Not sure?</span>
                      </div>
                    </div>
                    <div class="small-label">For example, this could be the length of your mortgage or how many year until you plan to retire.</div>
                  </div>
                  <div v-bind:style="$store.getters.infoBubble" v-if="showHowLongCover" class="mt-3">
                    <p>If you're covering a mortgage, you may want to consider coverage that matches the term of your mortgage or that extends to a specific time, such as
                        when you plan to retire or when your children reach a certain age. Alternatively, you may want to provide coverage for your family for a set period 
                        of time.</p>
                    <p>If you're unsure about how much coverage you require, our advisors are here to help. Please don't hesitate to call us at (insert number here) to 
                      speak with one of our knowledgeable team members. We can assist you in determining the right amount of coverage to suit your specific needs.</p>
                  </div>
                </template>

                <b-input-group append="years" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-form-input
                    v-model="term"
                    v-validate="{ required: true }"
                    :state="validateState('term')"
                    aria-describedby="term-feedback"
                    data-vv-as="term"
                    name="term"
                    type="number"
                  ></b-form-input>
                </b-input-group>
                <b-form-invalid-feedback id="term-feedback">{{ veeErrors.first('term') }}</b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        
        <div class="pb-3 pt-3">
          <b-row>
            <b-col xl="12" lg="12" md="12" sm="12">
              <div v-if="$store.getters.hasCustomerAgreement && askingMode == 'quote' && !$store.getters.twoPageQuote">
                <p>{{$store.getters.customerAgreeMessage || ""}}</p>
              </div>
              <div v-if="askingMode == 'quote' && !$store.getters.twoPageQuote" class="mt-5 mb-5">
                <a v-bind:style="$store.getters.elementColourBlockText" v-if="$store.getters.privacyLink" :href="$store.getters.privacyLink" target="_blank" class="mr-5">Privacy Policy</a>
                <a v-bind:style="$store.getters.elementColourBlockText" v-if="$store.getters.tosLink" :href="$store.getters.tosLink" target="_blank">Terms and Condition</a>
              </div>
              <b-button v-if="askingMode == 'quote'" class="large-button" variant="primary" v-on:click="gotoLifestyle" v-bind:style="$store.getters.primaryButtonBox">Next</b-button>
              <b-button v-if="askingMode == 'application'" class="large-button" variant="primary" v-on:click="applyDetails" v-bind:style="$store.getters.primaryButtonBox">{{ $store.getters.applyBtnText }}</b-button>
              
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
const moment = require('moment');

export default {
  name: "client-details",
  components: {
  },
  props: ['askingMode'],
  data() {
    return {
      pDobDay: null,
      pDobMonth: null,
      pDobYear: null,
      sDobDay: null,
      sDobMonth: null,
      sDobYear: null,
      showCoverType: false,
      showCoverWho: false,
      showHowMuchCover: false,
      showHowLongCover: false,
      pJobSearch: null,
      sJobSearch: null,
      isOtpWaiting: false,
      otpReference: "",
      enterAddressManually: false,
      showAddresses: false,
      quoteTypeOptions: [
        { text: "Family Cover", value: "family", description: "The lump sum payment and premium stay the same for the length of the policy term" },
        { text: "Mortgage Cover", value: "mortgage", description: "The amount of cover reduces over the length of the policy (term), the premium will stay the same. Suitable for a repayment mortgage." },
      ],
      policyTypeOptions: [
        { text: "Just Me", value: "single" },
        { text: "Add Another", value: "joint" },
      ],
      genderOptions: [
        { text: "Male", value: "male" },
        { text: "Female", value: "female" },
      ],
      benefitTypeOptions: [
        { text: "Death Benefit Only", value: "death" },
        { text: "Critical Illness Only", value: "cic" },
        { text: "Death with Critical Illness", value: "deathCic" },
      ],
      drivenOptions: [
        { text: "Benefit Driven", value: "benefit" },
        { text: "Premium Driven", value: "premium" }
      ],
      paymentFrequencyOptions: [
        { text: "Monthly Payment", value: "monthly" },
        { text: "Annual Payment", value: "annual" },
      ],
      titleOptions: [
        { text: "Mr", value: "mr" },
        { text: "Mrs", value: "mrs" },
        { text: "Miss", value: "miss" },
        { text: "Ms", value: "ms" },
        { text: "Master", value: "master" },
        { text: "Dr", value: "dr" },
      ],
      ynOptions: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 }
      ],
      relationshipOptions: [
        { text: "Spouse", value: "spouse" },
        { text: "Daughter", value: "daughter" },
        { text: "Son", value: "son" },
      ],
      maritalStatusOptions: [
        { text: "Single", value: "single" },
        { text: "Married", value: "married" },
        { text: "Divorced", value: "divorced" },
      ]
    }
  },
  watch: {
    pDobDay(){
      if(this.pDobDay && this.pDobMonth && this.pDobYear){
        var bDay = moment()
          .set('date', this.pDobDay)
          .set('month', this.pDobMonth)
          .set('year', this.pDobYear);
        this.pDateOfBirth = bDay.format('YYYY-MM-DD');
      }
    },
    pDobMonth(){
      if(this.pDobDay && this.pDobMonth && this.pDobYear){
        var bDay = moment()
          .set('date', this.pDobDay)
          .set('month', this.pDobMonth)
          .set('year', this.pDobYear);
        this.pDateOfBirth = bDay.format('YYYY-MM-DD');
      }
    },
    pDobYear(){
      if(this.pDobDay && this.pDobMonth && this.pDobYear){
        var bDay = moment()
          .set('date', this.pDobDay)
          .set('month', this.pDobMonth)
          .set('year', this.pDobYear);
        this.pDateOfBirth = bDay.format('YYYY-MM-DD');
      }
    },
    sDobDay(){
      if(this.sDobDay && this.sDobMonth && this.sDobYear){
        var bDay = moment()
          .set('date', this.sDobDay)
          .set('month', this.sDobMonth)
          .set('year', this.sDobYear);
        this.sDateOfBirth = bDay.format('YYYY-MM-DD');
      }
    },
    sDobMonth(){
      if(this.sDobDay && this.sDobMonth && this.sDobYear){
        var bDay = moment()
          .set('date', this.sDobDay)
          .set('month', this.sDobMonth)
          .set('year', this.sDobYear);
        this.sDateOfBirth = bDay.format('YYYY-MM-DD');
      }
    },
    sDobYear(){
      if(this.sDobDay && this.sDobMonth && this.sDobYear){
        var bDay = moment()
          .set('date', this.sDobDay)
          .set('month', this.sDobMonth)
          .set('year', this.sDobYear);
        this.sDateOfBirth = bDay.format('YYYY-MM-DD');
      }
    }
  },
  computed: {
    dayOptions: {
      get(){
        var days = [];
        for(var i = 1; i <= 31; i++){
          days.push({text: i, value: i});
        }
        return days;
      }
    },
    monthOptions: {
      get(){
        var months = [];
        for(var i = 0; i <= 11; i++){
          months.push({ text: i+1, value: i });
        }
        return months;
      }
    },
    yearOptions: {
      get(){
        var currentYear = moment().format('YYYY');
        var years = [];

        for(var i = 0; i < 100; i++){
            years.push(currentYear - i);
        }
        return years;
      }
    },
    pFirstName: {
      get() {
        return this.quoteForm.primaryMember.firstName;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.firstName = value;
        this.updateDetails(quoteForm);
      }
    },
    pLastName: {
      get() {
        return this.quoteForm.primaryMember.lastName;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.lastName = value;
        this.updateDetails(quoteForm);
      }
    },
    pDateOfBirth: {
      get(){
        return this.quoteForm.primaryMember.dateOfBirth;
      },
      set(value){
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.dateOfBirth = value;
        this.updateDetails(quoteForm);
      }
    },
    pGender: {
      get() {
        return this.quoteForm.primaryMember.gender;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.gender = value;
        this.updateDetails(quoteForm);
      }
    },
    pMaritalStatus: {
      get() {
        return this.quoteForm.primaryMember.maritalStatus;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.maritalStatus = value;
        this.updateDetails(quoteForm);
      }
    },
    pTitle: {
      get() {
        return this.quoteForm.primaryMember.title;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.title = value;
        this.updateDetails(quoteForm);
      }
    },
    pIsSmoker: {
      get(){
        return this.quoteForm.primaryMember.isSmoker ? 1 : 0;
      },
      set(value){
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.isSmoker = value;
        this.updateDetails(quoteForm);
      }
    },
    pOccupation: {
      get(){
        return this.quoteForm.primaryMember.occupation;
      },
      set(value){
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.occupation = value;
        this.updateDetails(quoteForm);
      }
    },
    pAnnualEarnings: {
      get(){
        return this.quoteForm.primaryMember.annualEarnings;
      },
      set(value){
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.annualEarnings = value;
        this.updateDetails(quoteForm);
      }
    },
    pWeightKg: {
      get(){
        return this.quoteForm.primaryMember.annualEarnings;
      },
      set(value){
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.annualEarnings = value;
        this.updateDetails(quoteForm);
      }
    },
    sFirstName: {
      get() {
        return this.quoteForm.secondaryMember.firstName;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.secondaryMember.firstName = value;
        this.updateDetails(quoteForm);
      }
    },
    sLastName: {
      get() {
        return this.quoteForm.secondaryMember.lastName;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.secondaryMember.lastName = value;
        this.updateDetails(quoteForm);
      }
    },
    sGender: {
      get() {
        return this.quoteForm.secondaryMember.gender;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.secondaryMember.gender = value;
        this.updateDetails(quoteForm);
      }
    },
    sMaritalStatus: {
      get() {
        return this.quoteForm.secondaryMember.maritalStatus;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.secondaryMember.maritalStatus = value;
        this.updateDetails(quoteForm);
      }
    },
    sDateOfBirth: {
      get(){
        return this.quoteForm.secondaryMember.dateOfBirth;
      },
      set(value){
        let quoteForm = { ...this.quoteForm };
        quoteForm.secondaryMember.dateOfBirth = value;
        this.updateDetails(quoteForm);
      }
    },
    sTitle: {
      get() {
        return this.quoteForm.secondaryMember.title;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.secondaryMember.title = value;
        this.updateDetails(quoteForm);
      }
    },
    sIsSmoker: {
      get(){
        return this.quoteForm.secondaryMember.isSmoker ? 1 : 0;
      },
      set(value){
        let quoteForm = { ...this.quoteForm };
        quoteForm.secondaryMember.isSmoker = value;
        this.updateDetails(quoteForm);
      }
    },
    sOccupation: {
      get(){
        return this.quoteForm.secondaryMember.occupation;
      },
      set(value){
        let quoteForm = { ...this.quoteForm };
        quoteForm.secondaryMember.occupation = value;
        this.updateDetails(quoteForm);
      }
    },
    sAnnualEarnings: {
      get(){
        return this.quoteForm.secondaryMember.annualEarnings;
      },
      set(value){
        let quoteForm = { ...this.quoteForm };
        quoteForm.secondaryMember.annualEarnings = value;
        this.updateDetails(quoteForm);
      }
    },
    telephoneNumber: {
      get() {
        return this.quoteForm.primaryMember.telephoneNumber;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.telephoneNumber = value;
        this.updateDetails(quoteForm);
      }
    },
    landlineNumber: {
      get() {
        return this.quoteForm.primaryMember.landlineNumber;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.landlineNumber = value;
        this.updateDetails(quoteForm);
      }
    },
    email: {
      get() {
        return this.quoteForm.primaryMember.email;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.email = value;
        this.updateDetails(quoteForm);
      }
    },
    postcode: {
      get() {
        return this.quoteForm.primaryMember.postcode;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.postcode = value;
        this.updateDetails(quoteForm);
      }
    },
    address1: {
      get() {
        return this.quoteForm.primaryMember.address1;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.address1 = value;
        this.updateDetails(quoteForm);
      }
    },
    address2: {
      get() {
        return this.quoteForm.primaryMember.address2;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.address2 = value;
        this.updateDetails(quoteForm);
      }
    },
    town: {
      get() {
        return this.quoteForm.primaryMember.town;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.town = value;
        this.updateDetails(quoteForm);
      }
    },
    county: {
      get() {
        return this.quoteForm.primaryMember.county;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.county = value;
        this.updateDetails(quoteForm);
      }
    },
    quoteType: {
      get(){
        return this.quoteForm.quoteType;
      },
      set(value){
        let quoteForm = { ...this.quoteForm };
        quoteForm.quoteType = value;
        this.updateDetails(quoteForm);
      }
    },
    policyType: {
      get(){
        return this.quoteForm.policyType;
      },
      set(value){
        let quoteForm = { ...this.quoteForm };
        quoteForm.policyType = value;
        this.updateDetails(quoteForm);
      }
    },
    benefitType: {
      get(){
        return this.quoteForm.benefitType;
      },
      set(value){
        let quoteForm = { ...this.quoteForm };
        quoteForm.benefitType = value;
        this.updateDetails(quoteForm);
      }
    },
    drivenType: {
      get(){
        return this.quoteForm.drivenType;
      },
      set(value){
        let quoteForm = { ...this.quoteForm };
        quoteForm.drivenType = value;
        this.updateDetails(quoteForm);
      }
    },
    paymentMode: {
      get(){
        return this.quoteForm.paymentMode;
      },
      set(value){
        let quoteForm = { ...this.quoteForm };
        quoteForm.paymentMode = value;
        this.updateDetails(quoteForm);
      }
    },
    deathBenefitAmount: {
      get(){
        return this.quoteForm.deathBenefitAmount;
      },
      set(value){
        let quoteForm = { ...this.quoteForm };
        quoteForm.deathBenefitAmount = value;
        this.updateDetails(quoteForm);
      }
    },
    criticalIllnessAmount: {
      get(){
        return this.quoteForm.criticalIllnessAmount;
      },
      set(value){
        let quoteForm = { ...this.quoteForm };
        quoteForm.criticalIllnessAmount = value;
        this.updateDetails(quoteForm);
      }
    },
    premium: {
      get(){
        return this.quoteForm.premium;
      },
      set(value){
        let quoteForm = { ...this.quoteForm };
        quoteForm.premium = value;
        this.updateDetails(quoteForm);
      }
    },
    term: {
      get(){
        return this.quoteForm.term;
      },
      set(value){
        let quoteForm = { ...this.quoteForm };
        quoteForm.term = value;
        this.updateDetails(quoteForm);
      }
    },
    addresses: {
      get(){
        var addresses = this.$store.state.pmi.addresses.map(address => {
          return Object.values(address).join(", ");
        });
        return addresses;
      }
    },
    selectedAddress: {
      get(){
        return this.$store.state.pmi.selectedAddress;
      },
      set(address){
        var x = address.split(',');
        var parsedX = {
          lineOne: x[0].trim(),
          lineTwo: x[1].trim(),
          lineThree: x[2].trim(),
          lineFour: x[3].trim(),
          locality: x[4].trim(),
          townCity: x[5].trim(),
          county: x[6].trim()
        }
        
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.address1 = parsedX.lineOne;
        quoteForm.primaryMember.address2 = parsedX.lineTwo;
        quoteForm.primaryMember.town = parsedX.townCity;
        quoteForm.primaryMember.county = parsedX.county;

        this.updateDetails(quoteForm);
        this.$store.commit("updateSelectedAddress", address);
      }
    },
    quoteForm: {
      get() {
        return this.$store.state.protection.quoteForm;
      }
    }
  },
  methods: {
    addMember: function(){
      this.$recordFrame();
      this.$store.commit("addMember");
      this.$resizeFrame();
    },
    removeMember: function(memberIndex){
      this.$store.commit("removeMember", memberIndex);
      this.$revertHeight();
    },
    updateDetails: function(data){
      this.askingMode == "application" ? this.$store.commit("updateApplicationDetails", data) : this.$store.commit("updateQuoteForm", data);
      this.$resizeFrame();
    },
    onContext: function(ctx) {
      this.formatted = ctx.selectedFormatted
      this.selected = ctx.selectedYMD
    },
    formatDate: function(value){
      return moment(value).format('DD/MM/YYYY');
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    applyDetails: function(){
      var _this = this;
      var delay = 0;

      if(!this.enterAddressManually){
        this.$resizeFrame();
        this.enterAddressManually = true;
        delay = 500;
      }

      setTimeout(function(){
        _this.$validator.validateAll().then(results => {
          if(results){
            _this.$store.commit("updateHasApplicationDetails", true);
            _this.$store.commit("updatePaymentStep","confirm");
            _this.$router.push({ name: 'PMIQuotePayment'});
          }
        });
      }, delay);
      
    },
    fetchQuotes: function(){
      this.$store.commit("updateLoadingStatus", true);
      this.$store.dispatch("getProtectionQuoteProducts").then(quotes => {
        console.log(quotes);
        var pluginMessage = { 
          messageType: "quoteResults",
          payload: {
            clientDetails: this.$formatProtection(this.quoteForm),
            quoteResult: quotes
          }
        };

        // Transmit to parent window.
        window.parent.postMessage(JSON.stringify(pluginMessage),"*");

        this.$router.push({ name: 'ProtectionQuoteResult' });
        this.$store.commit("updateLoadingStatus", false);
      })
      .catch(() => {
        this.$store.commit("updateLoadingStatus", false);
      });
    },
    fetchOtpQuotes: function(){
      this.$store.dispatch("getQuoteProducts").then(quotes => {
        var pluginMessage = { 
          messageType: "quoteResults",
          payload: {
            clientDetails: this.$formatPmi(this.quoteForm),
            quoteResult: quotes
          }
        };

        // Transmit to parent window.
        window.parent.postMessage(JSON.stringify(pluginMessage),"*");

        if(this.$route.name == 'PMIQuoteForm' && !this.isOtpWaiting){
          this.$store.commit("updateQuoteResult",[]);
        }
      })
      .catch(() => {
        this.$store.commit("updateLoadingStatus", false);
      });
    },
    gotoLifestyle(){
      this.$validator.validateAll().then(result => {
        if(result){
          this.$router.push({ name: 'ProtectionQuoteLifestyle' });
        }
      });
      
    },
    getProtectionQuotes: function(){
      console.log("This called");
      var _this = this;
      this.$resetFrame();
      this.$validator.validateAll().then(result => {
        if(result){
          this.$store.commit("updateViewMode", false);
          this.$store.commit("updateEditMode", false);
          
          if(!this.$store.getters.twoPageQuote){
            // Check if form has text validation
            this.$store.dispatch('hasVerification', { phoneNumber: this.telephoneNumber }).then(res => {
              console.log(res);
              if(res.data.hasTextVerification){
                this.otpReference = res.data.reference;

                // Fetch quotes on background
                this.fetchOtpQuotes();

                this.$swal.fire({
                  position: 'top',
                  showClass: {
                    popup: 'swal2-show mt-25',
                  },
                  title: 'Verification',
                  html: `Enter the OTP code you have received.<br/><small>Code expires in 5 minutes.</small>`,
                  input: 'number',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Verify',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  showDenyButton: true,
                  denyButtonText: 'Resend Code',
                  denyButtonColor: '#369A6E',
                  didOpen: () => {
                    this.isOtpWaiting = true;
                  },
                  preDeny: () => {
                    return new Promise(function(){
                      _this.$store.dispatch('hasVerification', { phoneNumber: _this.telephoneNumber, reference: _this.otpReference }).then(res => {
                        _this.$swal.enableButtons();
                        _this.otpReference = res.data.reference;
                      });
                    });
                  },
                  preConfirm: (otp) => {
                    return new Promise(function(resolve){
                      _this.$store.dispatch('verifyOtp', {
                        otp: otp,
                        reference: _this.otpReference
                      }).then(response => {
                        if(response.data.success){
                          resolve();
                          _this.$router.push({ name: 'PMIQuoteResult' });
                          _this.$store.commit("updateLoadingStatus", false);
                        } else {
                          _this.$swal.showValidationMessage("Invalid OTP code.");
                          _this.$swal.enableButtons();
                        }
                      });
                    });
                  }
                }).then((result) => {
                  if(result.dismiss == 'cancel'){
                    this.isOtpWaiting = false;
                    this.$store.commit("updateQuoteResult",[]);
                  }
                });
              } else {
                this.fetchQuotes(); 
              }
            }).catch(err => {
              console.log(err);
              if(this.$store.state.pmi.displayMode == 'demo'){
                this.fetchQuotes();
              }
            });
          } else {
            this.$router.push({ name: 'ProtectionQuoteResult' });
            this.$store.commit("updateLoadingStatus", false);
          }
          
        }
      });
    },
    getPostcodeAddress: function(){
      this.$store.dispatch("getPostcodeAddress").then(() => {
        this.enterAddressManually = true;
        this.showAddresses = true;

        this.$resizeFrame();
      });
    },
    openAddressBox: function(){
      this.enterAddressManually = true;
      this.$resizeFrame();
    }
  },
  mounted() {
    if(this.quoteForm.primaryMember.dateOfBirth){
      this.pDobDay = moment(this.quoteForm.primaryMember.dateOfBirth).format('D');
      this.pDobMonth = moment(this.quoteForm.primaryMember.dateOfBirth).format('M');
      this.pDobYear = moment(this.quoteForm.primaryMember.dateOfBirth).format('YYYY');
    }

    if(this.quoteForm.secondaryMember.dateOfBirth){
      this.sDobDay = moment(this.quoteForm.secondaryMember.dateOfBirth).format('D');
      this.sDobMonth = moment(this.quoteForm.secondaryMember.dateOfBirth).format('M');
      this.sDobYear = moment(this.quoteForm.secondaryMember.dateOfBirth).format('YYYY');
    }
    this.$resizeFrame();
  },
  created(){
    document.getElementsByTagName('body')[0].addEventListener('click', () => {
      this.$root.$emit('bv::hide::popover')
    });
  }
}
</script>

<style lang="scss">
.quote-popover {
  min-width: 200px;

  .popover-body {
    padding: 0;
  }

  .popover-highlight {
    width: 100%; 
    height: 5px; 
    background-color: #FF0000; 
    border-radius: 5px 5px 0 0;
    margin-top: -1px;
  }
}

.protection-quote-form .btn-label {
  font-weight: 700;
}

.protection-quote-form .btn-description {
  font-weight: normal !important;
}

.enter-address {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .sm-font {
    font-size: 10px !important;
  }
}

.swal2-styled.swal2-deny {
    border: 0;
    border-radius: .25em;
    background: initial;
    background-color: #3085d6;
    color: #fff;
    font-size: 1.0625em;
}

.w-170px {
  width: 170px;
}

.small-label {
  font-size: 9px
}

.protection-quote-form .btn-group label.btn {
  background: transparent;
  box-shadow: none;
}

.block-view {
  display: block !important;
}

.protection-quote-form .btn {
  padding-top: 11px;
  padding-bottom: 11px;
}

.not-sure {
  cursor: pointer;
}
</style>